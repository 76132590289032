<template>
    <div class="themestyle">
          <el-form-item label="社区主题" prop="themeType">
            <el-radio-group v-model="siteInfo.themeType" size='medium'>
              <el-radio-button border label="1">红色</el-radio-button>
              <el-radio-button border label="2">橙色</el-radio-button>
              <el-radio-button border label="3">黄色</el-radio-button>
              <el-radio-button border label="4">绿色</el-radio-button>
              <el-radio-button border label="5">蓝色</el-radio-button>
              <el-radio-button border label="6">其他</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col :span="3">
              <el-form-item label="主题色" prop="themeColor">
                <el-input class="mar-bot10" v-model="siteInfo.themeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.themeColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="第二主题色" prop="secondThemeColor">
                <el-input class="mar-bot10" v-model="siteInfo.secondThemeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.secondThemeColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="底部背景" prop="footerBgc">
                <el-input class="mar-bot10"  v-model="siteInfo.footerBgc" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.footerBgc"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="侧边栏背景色" prop="rightBarBackgroundColor">
                <el-input class="mar-bot10" v-model="siteInfo.rightBarBackgroundColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.rightBarBackgroundColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="字体颜色" prop="fontColor">
                <el-input class="mar-bot10" v-model="siteInfo.fontColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.fontColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="第二字体颜色" prop="secondFontColor">
                <el-input class="mar-bot10" v-model="siteInfo.secondFontColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.secondFontColor"></el-color-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item v-if="siteInfo.themeType!=6" label="主题示例图片" prop="">
            <img :src="exampleImg"  style="width: 1000px;height:343px;" >
          </el-form-item>
          <el-form-item label="首页banner图" prop="">
            <img v-if="siteInfo.bannerBg" :src="siteInfo.bannerBg" style="width: 1000px;height:343px;"/>
            <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-success="handleSuccess4"
                :file-list="fileLists"
                list-type="picture"
                :show-file-list='false'>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>

          <el-row>
            <el-col :span="4">
              <el-form-item label="导航栏logo" prop="navibarLogo">
                <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :on-success="handleSuccess"
                  :file-list="fileLists"
                  list-type="picture"
                  :show-file-list='false'>
                  <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                </el-upload>
                <img v-if="siteInfo.navibarLogo" :src="siteInfo.navibarLogo" :style="{background: siteInfo.headerBgc}" class="logo-class">
              </el-form-item>
              </el-col>
              <!-- <el-col :span="4" >
                <el-form-item label="底部导航栏logo"  prop="footBarLogo">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess1"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.footBarLogo"  :style="{background: siteInfo.footerBgc}" :src="siteInfo.footBarLogo"  class="foot-logo-class">
                </el-form-item>
              </el-col> -->

              <el-col :span="4">
                <el-form-item label="ICON" prop="icon">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess5"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.icon" :src="siteInfo.icon" class="icon-logo-class">
                </el-form-item>
              </el-col>
          </el-row>
          <el-row>
             <el-col :span="8">
              <el-form-item label="客服微信二维码" prop="weixinQcodeCustService">
                <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :on-success="handleSuccess2"
                  :file-list="fileLists"
                  :show-file-list='false'
                  list-type="picture">
                  <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                </el-upload>
                <img v-if="siteInfo.weixinQcodeCustService" :src="siteInfo.weixinQcodeCustService" class="weixinQcodeCust-logo-class">
              </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公众号二维码" prop="weixinQcodePub">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess3"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.weixinQcodePub" :src="siteInfo.weixinQcodePub" class="weixinQcodePub-logo-class">
                </el-form-item>
              </el-col>
              <div style="width: 1000px;text-align: right;margin: 50px 0;">
                <el-button plain @click="cancel">取消</el-button>
                <el-button :loading="loading" type="primary" @click="doSubmit()">确认</el-button>
              </div>
          </el-row>
    </div>
</template>
<script>

export default {
    props: {
       siteInfo: {
           type:Object,
           required:true
       },
    //    exampleImg: {
    //        type:String,
    //        required:true
    //    }
    },
    data() {
      return {
        uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
        loading:false,
        fileLists:[],
        exampleImg:'',
        themeColors:[
            {
            themeColor: "#f4467d",
            secondthemeColor: "#f35889",
            fontColor:'#1c2179',
            secondFontColor:'#7679b7',
            footerBgc: "#5126bd",
            rightBarBackgroundColor: "#E74C3C",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e7a565b0b040403293f365d33b0a5a5c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/43d1c6c5b0dd4eb7810560241bfec39e.jpg"
            },
            {
            themeColor: "#fd5e17",
            secondthemeColor: "#fe793e",
            fontColor:'#1c2179',
            secondFontColor:'#7679b7',
            footerBgc: "#1b2251",
            rightBarBackgroundColor: "#FA6C0F",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/0ba72dbdca814e9cae8c7202879338db.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/d3f0145c46d7468ba1afc5f5d1d37cb4.jpg"

            },
            {
            themeColor: "#fdaf2e",
            secondthemeColor: "#fec465",
            fontColor:'#191140',
            secondFontColor:'#6e7286',
            footerBgc: "#191140",
            rightBarBackgroundColor: "#FFBA02",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/77b044c4179348838bd4f798b2e78278.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/d0a1fe1ef25146a5846cc89c83fc093c.jpg"

            },
            {
            themeColor: "#21bf73",
            secondthemeColor: "#31d385",
            fontColor:'#000000',
            secondFontColor:'#666666',
            footerBgc: "#0f0c23",
            rightBarBackgroundColor: "#11AC80",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c78623cbc1dd48ceb529c4227c0ef924.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/7eee204dc6b64a0490f00abb433a1560.png"

            },
            {
            themeColor: "#01c3ff",
            secondthemeColor: "#52d6ff",
            fontColor:'#000000',
            secondFontColor:'#666666',
            footerBgc: "#0073ff",
            rightBarBackgroundColor: "#207AF4",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/6a1678f8a36244a0b8aff53f3facca2c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/673b70e68527446994ddeb051176fb92.jpg"
            }
        ],
      }
  },
   watch:{
    'siteInfo.themeType': {
        handler(num){
            if(num<6) {
              var nums= num-1;
              this.siteInfo.themeColor=this.themeColors[nums].themeColor;
              this.siteInfo.secondThemeColor=this.themeColors[nums].secondthemeColor;
              this.siteInfo.footerBgc=this.themeColors[nums].footerBgc;
              this.siteInfo.rightBarBackgroundColor=this.themeColors[nums].rightBarBackgroundColor;
              this.siteInfo.fontColor=this.themeColors[nums].fontColor;
              this.siteInfo.secondFontColor=this.themeColors[nums].secondFontColor;
              this.siteInfo.bannerBg=this.themeColors[nums].bannerBg;
              this.exampleImg=this.themeColors[nums].exampleImg;
            }
        },
        deep: true
    }
   },
   created() {
        if(this.siteInfo.themeType==null ){
            this.siteInfo.themeType = 1
        }else if(this.siteInfo.themeType==6 ){
          this.themeColors[5].themeColor = this.siteInfo.themeColor
          this.themeColors[5].secondthemeColor = this.siteInfo.secondThemeColor
          this.themeColors[5].footerBgc = this.siteInfo.footerBgc
          this.themeColors[5].rightBarBackgroundColor = this.siteInfo.rightBarBackgroundColor
          this.themeColors[5].bannerBg = this.siteInfo.bannerBg
          this.themeColors[5].fontColor = this.siteInfo.fontColor
          this.themeColors[5].secondFontColor = this.siteInfo.secondFontColor
        }else {
          this.exampleImg=this.themeColors[this.siteInfo.themeType-1].exampleImg
        }
   },
  methods: {
      doSubmit() {
        this.$emit('doSubmit');
      },
      cancel() {

      },
    handleSuccess(response){
        this.$emit('handleSuccess',response);
        //上传成功要处理的事
    },
    handleSuccess1(response){
        //上传成功要处理的事
        this.$emit('handleSuccess1',response);
    },
    handleSuccess2(response){
        this.$emit('handleSuccess2',response);
        //上传成功要处理的事
    },
    handleSuccess3(response){
        this.$emit('handleSuccess3',response);
        //上传成功要处理的事
    },
    handleSuccess5(response){
        this.$emit('handleSuccess5',response);
        //上传成功要处理的事
    },
    handleSuccess4(response){
        this.$emit('handleSuccess4',response);
        //上传成功要处理的事
    },
  }
}
</script>
<style lang="less" scope>
    .logo-class{
    width:auto;
    height:30px;
    }
    .foot-logo-class{
    width:auto;
    height:38px;
    }
    .icon-logo-class{
    width:40px;
    height:40px;
    }
    .weixinQcodeCust-logo-class{
    width:75px;
    height:75px;
    }
    .weixinQcodePub-logo-class{
    width:125px;
    height:125px;
    }
</style>
