<template>
  <div class='mainBody'>
    <el-form ref="form" :model="siteInfo" :rules="rules"  size="small" label-width="120px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="公司信息" name="first">
          <el-row class="viewWidth">
            <el-col :span="8">
              <el-form-item label="站点ID" prop="id">
                <el-input v-model="siteInfo.siteId" style="width: 210px;"  :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="品牌名称" prop="id">
                  <el-input v-model="siteInfo.siteName" style="width: 210px;"/>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="网站域名" prop="id">
                  <el-input v-model="siteInfo.domainName" style="width: 210px;"/>
                </el-form-item>
            </el-col>
          </el-row>
          <div v-if="siteInfo.siteType !==3">
             <el-form-item label="公司简介" prop="companyIntro">
              <el-input type="textarea"  v-model="siteInfo.companyIntro" style="width: 900px;"/>
            </el-form-item>
            <el-form-item label="团队介绍" prop="teamIntro">
              <el-input type="textarea"  v-model="siteInfo.teamIntro" style="width: 900px;"/>
            </el-form-item>
            <el-form-item label="愿景" prop="mission">
              <el-input type="textarea"  v-model="siteInfo.mission" style="width: 900px;"/>
            </el-form-item>
            <el-form-item label="目标" prop="goal">
              <el-input type="textarea"  v-model="siteInfo.goal" style="width: 900px;"/>
            </el-form-item>
            <el-form-item label="使命" prop="expectation">
              <el-input type="textarea" v-model="siteInfo.expectation" style="width: 900px;"/>
            </el-form-item>
          </div>
          <el-form-item label="cnzz统计代码" prop="statisticsCode">
            <el-input type="textarea" v-model="siteInfo.statisticsCode" style="width: 900px;"/>
          </el-form-item>
          <el-row class="viewWidth">
            <el-col :span="8">
              <el-form-item label="客服QQ" prop="qqCustService">
                <el-input v-model="siteInfo.qqCustService" style="width: 210px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="客服电话" prop="custServicePhone">
                  <el-input v-model="siteInfo.custServicePhone" style="width: 210px;"/>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="工作时间" prop="workTime">
                  <el-input v-model="siteInfo.workTime" style="width: 210px;"/>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="viewWidth">
            <el-col :span="8">
              <el-form-item label="客服邮箱" prop="custServiceEmail">
                <el-input v-model="siteInfo.custServiceEmail" style="width: 210px;"/>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row class="viewWidth">
            <el-col :span="8">
              <el-form-item label="Whatsapp" prop="whatsapp">
                <el-input v-model="siteInfo.whatsapp" style="width: 210px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Telegram" prop="telegram">
                <el-input v-model="siteInfo.telegram" style="width: 210px;"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Skype" prop="skype">
                <el-input v-model="siteInfo.skype" style="width: 210px;"/>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="width: 1000px;text-align: right;margin: 50px 0;">
            <el-button plain @click="cancel">取消</el-button>
            <el-button :loading="loading" type="primary" @click="doSubmit()">确认</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="主题样式" name="second">
          <theme-style
            v-if="siteInfo.siteType == 2"
            :siteInfo="siteInfo"
            @doSubmit="doSubmit"
            :exampleImg="exampleImg"
            @handleSuccess="handleSuccess"
            @handleSuccess1="handleSuccess1"
            @handleSuccess2="handleSuccess2"
            @handleSuccess3="handleSuccess3"
            @handleSuccess4="handleSuccess4"
            @handleSuccess5="handleSuccess5"
          ></theme-style>
          <simple-style
            v-if="siteInfo.siteType == 3"
            :siteInfo="siteInfo"
            @doSubmit="doSubmit"
            :exampleImg="exampleImg"
            @handleSuccess="handleSuccess"
            @handleSuccess1="handleSuccess1"
            @handleSuccess2="handleSuccess2"
            @handleSuccess3="handleSuccess3"
            @handleSuccess4="handleSuccess4"
            @handleSuccess5="handleSuccess5"
          ></simple-style>
        </el-tab-pane>
        <el-tab-pane label="邮件配置" name="third">
          <EmailConfig :siteId='siteId'/>
        </el-tab-pane>
        <el-tab-pane
          v-if="subsiteVisable"
          label="订阅设置"
          name="fourth"
        >
          <subsite
            v-if="activeName == 'fourth'"
            :siteId="siteId"
          />
        </el-tab-pane>
        <el-tab-pane label="功能配置" name="five">
          <Feature :siteId="siteId" />
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
  import Feature from '@/components/cms/site/Feature'
import EmailConfig from '@/components/cms/site/emailConfig'
import subsite from '@/components/cms/site/subsite.vue'
import themeStyle from '@/components/cms/site/themestyle.vue'
import simpleStyle from '@/components/cms/site/simplestyle.vue'

import { add , edit, getSiteConfig,getSubsiteRule } from "@/api/cms/srSiteConfig";
import { editCmsModuleContent , getSrCmsModuleContent } from "@/api/cms/srCmsModuleContent";

import Clipboard from 'clipboard';
export default {
  components: { EmailConfig,subsite,themeStyle,simpleStyle,Feature },
  data() {
    return {
      activeName: 'first',
      newHomeBannerModuleContent:{},
      loading:false,
      fileLists:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      siteId:'',
      exampleImg:'',
      rules:{
        siteId: [{ required: true, message: "所属站点不能为空", trigger: "blur" }],
      },
      siteInfo:{
        siteId: -99,
        radio: 1,
        siteName: "",
        domainName: "",
        companyIntro: " ",
        teamIntro: " ",
        mission: " ",
        goal: " ",
        expectation: " ",
        qqCustService: "",
        custServicePhone: "",
        workTime: "",
        navibarLogo: "",
        icon: "",
        weixinQcodePub: "",
        weixinQcodeCustService: "",
        footBarLogo: "",
        custServiceEmail: "",
        whatsapp: '',
        telegram: '',
        skype: '',

        bannerBg: "",
        themeColor: "#FD5E17",
        themeType: 1,
        secondThemeColor: "#FF7539",
        headerBgc: "#333333",
        footerBgc: "#454545",
        rightBarBackgroundColor: "#999999",
        statisticsCode: "",
        language: "zh_CN",
        templateType: 1
        },
     subsiteVisable: false
    }
  },
  created() {
    this.siteId = this.$route.query.siteId+''
    this.siteInfo.siteId = this.siteId
    this.getSrCmsModuleContentInfo()
    this.getSiteConfigInfo()
    this.getSubsiteRule()
  },
  computed: {
    themeType() {
  　　　　return this.siteInfo.themeType
  　}
  },
  methods:{
    getSiteConfigInfo(){
      let params = { siteId: this.siteId };
      getSiteConfig(params).then(res => {
        if(res != null){
          this.siteInfo = res;
        }
      });
    },
    getSubsiteRule() {
      let params = { siteId: this.siteId };
      getSubsiteRule(params).then(res => {
        if(res.content && res.content.length > 0) {
          this.subsiteVisable = true
        } else {
          this.subsiteVisable = false
        }
      });
    },
    getSrCmsModuleContentInfo(){
       let params = { page: 0, size: 100, sort: "id,desc" ,siteId:this.siteId,moduleName:'newHomeBanner'};
       getSrCmsModuleContent(params).then(res => {
        if(res.totalElements != 0){
          this.newHomeBannerModuleContent = res.content[0];
        }
      });
    },
    cancel() {

    },
    doSubmit() {
      this.loading = true;
      this.doEdit();
      this.editCmsModuleContent();
    },
    editCmsModuleContent(){
      if(this.newHomeBannerModuleContent == null || JSON.stringify(this.newHomeBannerModuleContent) == "{}") {
        return
      }
      let moduleContent = JSON.parse(this.newHomeBannerModuleContent.moduleContent)
      moduleContent.bannerUrl = this.siteInfo.bannerBg
      this.newHomeBannerModuleContent.moduleContent = JSON.stringify(moduleContent)
      editCmsModuleContent( this.newHomeBannerModuleContent).then(res => {
        }).catch(err => {

        });
    },
    doEdit() {
      edit(this.siteInfo)
        .then(res => {
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
     },
    handleSuccess(response){
        //上传成功要处理的事
        this.siteInfo.navibarLogo=response.data.content[0].url;
    },
    handleSuccess1(response){
        //上传成功要处理的事
        this.siteInfo.footBarLogo=response.data.content[0].url;
    },
    handleSuccess2(response){
        //上传成功要处理的事
        this.siteInfo.weixinQcodeCustService=response.data.content[0].url;
    },
    handleSuccess3(response){
        //上传成功要处理的事
        this.siteInfo.weixinQcodePub=response.data.content[0].url;
    },
    handleSuccess5(response){
        //上传成功要处理的事
        this.siteInfo.icon=response.data.content[0].url;
    },
    handleSuccess4(response){
        //上传成功要处理的事
        this.siteInfo.bannerBg=response.data.content[0].url;
    },

  }
}
</script>

<style scoped lang="less">
  .viewWidth {
    width: 1000px;
  }
  .mar-bot10 {
    margin-bottom: 10px;
  }
.el-collapse {
  margin-left: 20px;
  margin-right: 20px;
}
.mainBody{
  margin-left: 20px;
  margin-right: 20px;
  margin-top:20px;
}
.logo-class{
  width:auto;
  height:30px;
}
.foot-logo-class{
  width:auto;
  height:38px;
}
.icon-logo-class{
  width:40px;
  height:40px;
}
.weixinQcodeCust-logo-class{
  width:75px;
  height:75px;
}
.weixinQcodePub-logo-class{
  width:125px;
  height:125px;
}
// 订阅设置
.el-bottom {
  margin-left: 30px;
 }
  .el-bottom, .el-bottom .el-col{
    margin-bottom: 10px;
  }
  .el-row-bottom {
    margin-bottom: 20px;
  }
  .checkInput {
    width: 150px;
    margin-right: 10px;
  }
  .LimitBox{
    margin-top: 20px;
    .LimitBottom {
      margin-bottom: 10px;
    }
  }
  .modifyDesc {
    border-top: 1px solid #333333;
    margin-bottom: 150px;
    max-width: 1200px;
    .title {
      font-size: 24px;
      color: #333;
      padding: 20px;
      font-weight: 500;
    }
    .from-bttom-desc {
      text-align: center;
    }
    .th {
      margin-bottom: 10px;
      line-height: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #333;
    }
  }

</style>
