<template>
    <div class="subsite">
        <el-form
            ref="subSetform"
            :model="subSetform"
            :rules="subSetRules"
            class="subSet"
            style="width:1000px;padding:0 50px;"
            size="small"

        >
            <el-form-item label="收费功能" prop="status">
              <el-row :gutter="24">
                <el-col :span="10">
                    <el-form-item >
                      <el-radio v-model="subSetform.status" :label="1">启用</el-radio>
                      <el-radio v-model="subSetform.status" :label="0">禁用</el-radio>
                    </el-form-item>
                </el-col>

              </el-row>
            </el-form-item>
            <el-form-item
              v-if="subSetform.openWallet"
            >
              <p class="title-p">商户信息</p>
            </el-form-item>
          <el-form-item
            v-if="subSetform.openWallet"
          >
            <el-row :gutter="40">
              <el-col :span="7">
                <el-form-item
                  label="钱包商户号"
                  prop="merchantId"
                >
                  <el-input
                    style="width:130px;"
                    v-model="subSetform.merchantId"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="钱包密钥"
                  prop="signalKey"
                >
                  <el-input
                    style="width:130px;"
                    v-model="subSetform.signalKey"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="站点分成"
                  prop="proportion"
                >
                  <div
                    class="proportion-outer pr"
                  >
                    <el-input
                      style="width:130px;"
                      v-model="subSetform.proportion"
                    ></el-input>
                    <span class="pa suffix">%</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <p class="title-p">订阅费 (按月收费)</p>
          </el-form-item>
          <el-form-item
            label="免费体验"
            prop="freePeriod"
          >
            <el-input
              v-model="subSetform.freePeriod"
              style="width:130px;margin-right:10px;"
            ></el-input>天
          </el-form-item>
          <el-form-item>
            <el-row :gutter="24" style="margin-bottom: 15px;">
              <div
                v-for="(item,index) in subSetform.ruleFees"
                :key="index"
                :span="24"
              >
                <el-row style="margin:0;" :gutter="24" >
                  <div v-if="item.price==0">
                    <el-col :span="12">
                      <el-checkbox
                        :checked="item.enable == 1?true:false"
                        disabled
                      ></el-checkbox>
                      <span>免费</span>
                    </el-col>
                  </div>
                  <div v-if="item.price > 0">
                    <el-col :span="12">
                      <el-checkbox
                          :checked="item.enable == 1?true:false"
                          @change="checkBoxChange($event,item,index)"></el-checkbox>
                      <span>{{item.price}}$/月</span>
                    </el-col>
                    <el-col :span="6" :offset="5" style="color: #0066CC;" >
                      <div @click="limitBoxClick(item,index)">信号限制</div>
                    </el-col>
                    <el-col :span="24">
                      <span v-for="(subLimit,subitem) in item.indexList" :key="subitem">
                        <span>{{subLimit.indexName}}{{subLimit.indexLimit}}{{subLimit.indexValue}}</span>；
                      </span>
                    </el-col>
                  </div>
                  <div v-if="!!item.price && item.price == -1">
                    <el-col :span="12">
                      <el-checkbox :checked="item.enable == 1?true:false" @change="checkBoxChange($event,item,index)"></el-checkbox>
                      <span>自定义价格</span>
                    </el-col>
                    <el-col :span="6" :offset="5" style="color: #0066CC;" >
                      <div @click="limitBoxClick(item,index)">信号限制</div>
                    </el-col>
                    <el-col :span="24">
                      <span v-for="(subLimit,subitem) in item.indexList" :key="subitem">
                        <span>{{subLimit.indexName}}{{subLimit.indexLimit}}{{subLimit.indexValue}}</span>；
                      </span>
                    </el-col>
                  </div>
                </el-row>
              </div>
            </el-row>
          </el-form-item>
          <el-form-item
            v-if="subSetform.openSettlement"
          >
            <p class="title-p">表现费 (每周结算,按盈利分成)</p>
          </el-form-item>
          <el-form-item
            v-if="subSetform.openSettlement"
            label="订阅保证金"
            prop="margin"
          >
            <el-col :span="6">
              <div class="margin-box pr">
                <el-input
                  v-model="subSetform.margin"
                ></el-input>
                <span class="suffix">USDT</span>
              </div>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col v-if="subSetform.openCommission">
              <p class="title-p">多级返佣</p>
              <!--<div class="done-line">
                <span>返佣配置</span>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  @click="addHandle"
                >新增
                </el-button>
              </div>-->
              <rate-table
                :rate-table="subSetform.levels"
                @push-rate="pullData"
              ></rate-table>
            </el-col>
          </el-form-item>
          <el-form-item>
            <div class="btn-outer-box">
              <el-button plain @click="cancel">取消</el-button>
              <el-button :loading="loading" type="primary" @click="SubConfirm">确认</el-button>
            </div>
          </el-form-item>
        </el-form>

        <el-dialog
        width="500px"
        top="25vh"
        class="innerDialog"
        title="信号限制"
        :visible.sync="limitVisible"
        append-to-body>
        <div class="LimitBottom">
            <el-form :model="limitFrom" ref="limitFrom"  size="small" label-position="right">
            <el-row :gutter="24" v-for="(item,index) in limitFrom.limitData" :key="index">
                <el-col :span="12">
                    <el-form-item :label="item.indexName" style="text-align: right;" :prop="`limitData[${index}].indexLimit`" :rules="{ required: true, message: '必选', trigger: 'blur' }">
                      <el-select
                        class="align-top"
                        clearable
                        v-model="item.indexLimit"
                        filterable
                        placeholder=""
                        style="width: 100px"
                      >
                          <el-option
                          v-for="item in Symbol"
                          :key="item.label"
                          :label="item.label"
                          :value="item.label">
                          </el-option>
                      </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                <el-form-item :prop="`limitData[${index}].indexValue`" :rules="{ required: true, message: '必填，输入正数', trigger: 'blur' }">
                    <el-input v-model.number="item.indexValue" type="number" style="width: 150px;margin-right: 10px;"  @input="indexValueChange($event)"></el-input>{{item.displaySuffix}}
                </el-form-item>
                </el-col>
            </el-row>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="limitVisible = false">取消</el-button>
            <el-button :loading="loading" type="primary" @click="limitConfirm">确认</el-button>
        </div>
        </el-dialog>
    </div>
</template>
<script>
  import rateTable from './subsite/rateTable'
import { validatgreater,validatpositiveInt } from '@/utils/validate'
import {getSubSiteOne,updatesubSite } from "@/api/cms/srSiteConfig";
export default {
  components:{
    rateTable,
  },
    props: {
        siteId: {
            type: String,
            required: true
        }
    },
    created() {
      this.getSubSiteOne()
    },
    data() {
       var checkLowest = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('免费订阅天数不能为空'));
        }
        setTimeout(() => {
          if (!validatpositiveInt(value)) {
            callback(new Error('请输入正整数'));
          } else {
            callback();
          }
        }, 500);
      };
      var checkMargin = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('保证金不能为空'));
        }
        setTimeout(() => {
          if (!validatpositiveInt(value)) {
            callback(new Error('请输入大于0的正整数'));
          } else {
            callback();
          }
        }, 500);
      };
      return {
          loading: false,
        // 订阅设置
        subSetform: {
          freePeriod: '',
          ruleFees: [],
          siteId: null,
          status: null,
          openWallet: 0,
          merchantId: '',
          signalKey: '',
          margin: '',
          proportion: '',
          openSettlement: 0,
          openCommission: 0,
          levels: []
        },
      subSetRules: {
        status: [{ required: true, message: "状态为必填", trigger: "blur" }],
        siteId: [{ required: true, message: "所属站点不能为空", trigger: "blur" }],
        isMaster:[{ required: true, message: "选择收费功能", trigger: "blur" }],
        freePeriod: [{ required: true,validator: checkLowest, trigger: 'blur' }],
        margin:[
          { validator: checkMargin, trigger: 'blur' }
        ]
      },
      limitRules: {
        checkPrice: [{ required: true, message: "状态为必填", trigger: "blur" }],
        price: [ { type: 'number',required: true, message: '必须为数字', trigger: 'blur'} ],
      },
      limitVisible: false,
      limitFrom: {},
      radio: 1,
      limitIndex: Number,
      Symbol:[
          {value: '1',label: '<'},
          {value: '2',label: '>'},
          {value: '3',label: '='},
          {value: '4',label: '<='},
          {value: '5',label: '>='},
          {value: '6',label: '!='},
      ],
      LimitList: [],
      brokerList: '',
      // 分佣比例总和
      billdialog: false,
      }
  },
  methods: {
    cancel(){

    },
    pullData(){
      this.getSubSiteOne();
    },
    // 订阅设置获取数据
    getSubSiteOne() {
      var params = {
        siteId:this.$route.query.siteId
      }
      getSubSiteOne(params).then(res => {
        for (let i = 0; i < res.levels.length; i++) {
          res.levels[i].proportionSelf = res.levels[i].proportion * 100;
        }
        res.proportion = res.proportion * 100;
        this.subSetform = res;
      });
    },
    // 订阅确认
    SubConfirm() {
      this.$refs["subSetform"].validate(valid => {
        if (valid) {
          let params = {
            ...this.subSetform,
          }
          params.proportion = this.subSetform.proportion / 100;
          updatesubSite(params).then(res => {
            this.$notify({
              title: '修改成功',
              type: 'success',
              duration: 2500
            })
            this.loading = false
            this.getSubSiteOne();
          }).catch(err => {
            this.loading = false
            console.log(err.response.data.message)
          })
        }
      });
    },
   // 信号限制弹出
   limitBoxClick(item,index) {
     this.limitVisible = true;
     this.limitFrom.limitData = this.subSetform.ruleFees[index].indexList
     this.limitIndex = index
   },
   checkBoxChange(e,item,index) {
     if(e) {
       this.subSetform.ruleFees[index].enable = 1
     } else {
       this.subSetform.ruleFees[index].enable = 0
     }
   },
    indexValueChange(e) {
      this.$forceUpdate();
    },
    // 订阅设置弹窗
    limitConfirm() {
      this.$refs["limitFrom"].validate(valid => {
        if (valid) {
          this.limitVisible = false;
          for (var i = 0; i < this.subSetform.ruleFees.length; i++) {
            this.subSetform.ruleFees[this.limitIndex].indexList = this.limitFrom.limitData
          }
        }
      });
    },
  }
}
</script>
<style lang="less">
  .proportion-outer {
    position: relative;

  }
  .suffix {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
  }
  .margin-box {
    .suffix {
      right: 10px;
    }
  }
  .subsite {
    .title-p {
      height: 40px;
      line-height: 40px;
      background-color: #f1f3f5;
      font-size: 16px;
      position: relative;
      padding-left: 30px;
      &::before {
        content: ""; // 伪元素必须设置 content
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgb(48, 65, 86);
      }
    }
  }
  .btn-outer-box {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 30px;
  }
</style>
