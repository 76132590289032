<template>
  <el-row class="rate-table" :gutter="20">
    <el-col :span="2">返佣配置</el-col>
    <el-col :span="16">
      <el-table
        border
        class="rebate-table"
        size="mini"
        :data="commissionList"
      >
        <el-table-column
          prop="name"
          label="名称"
        >
          <template slot-scope="scope">
            <div class="name-inner">
              <span v-if="!scope.row.canEdit">{{scope.row.levelName}}</span>
              <el-input
                v-else
                v-model="scope.row.levelName"
                placeholder="10个字以内"
                maxlength="10"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="返佣比例"
        >
          <template slot-scope="scope">
            <div class="name-inner">
              <span v-if="!scope.row.canEdit">{{scope.row.proportionSelf}}</span>
              <el-input
                v-else
                v-model="scope.row.proportionSelf"
                placeholder="0-100的整数"
                maxlength="10"
              >
                <span slo="suffix">%</span>
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
        >
          <template slot-scope="scope">
            <div class="name-inner">
                  <span
                    class="span-btn"
                    v-if="!scope.row.canEdit"
                    @click="editHandle(scope.row,scope.$index)"
                  >编辑</span>
              <span
                class="span-btn"
                v-if="scope.row.canEdit"
                @click="saveHandle(scope.row)"
              >保存</span>
              <span
                class="span-btn"
                v-if="getDeleteFlag(scope.row,scope.$index)"
                @click="deleteHandle(scope.row,scope.$index)"
              >删除</span>
              <span
                class="span-btn"
                v-if="scope.row.canEdit"
                @click="cancelHandle(scope.row)"
              >取消</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="4">
      <el-button
        class=""
        size="small"
        round
        @click="addHandle"
      >添加</el-button>
    </el-col>
  </el-row>
</template>

<script>
  import { updateLevel,deleteLevel,addLevel } from '@/api/cms/srSiteConfig'
  import { noZeroInit } from '@/utils/validate'
  export default {
    name: 'rateTable',
    props: {
      rateTable: {
        type: Array,
        default(){
          return [];
        }
      }
    },
    watch: {
      rateTable(newValue) {
        if(newValue){
          this.commissionList = JSON.parse(JSON.stringify(newValue));
        }

      }
    },
    data() {
      return {
        commissionList: JSON.parse(JSON.stringify(this.rateTable)),
        cacheRebateData:[],
      }
    },
    methods: {
      pullAjaxRateData(){
        this.$emit('push-rate', this.commissionList);
      },
      getDeleteFlag(row,index){
        return !row.canEdit && index;
      },
      saveCacheRebateData(){
        this.cacheRebateData = JSON.parse(JSON.stringify(this.commissionList));
      },
      addHandle(){
        this.saveCacheRebateData();
        this.commissionList.push({
          calculate: 0,
          levelName: "",
          proportionSelf: 0,
          proportion: 0,
          siteId: 0,
          userId: 0,
          canEdit: true
        });
      },
      editHandle(row,index) {
        this.saveCacheRebateData();
        this.$set(this.commissionList[index],'canEdit',true);
        // this.commissionList[index].canEdit = true;
      },
      saveHandle(row) {
        if(!row.levelName){
          this.$message({
            message: '请输入名称',
            type: 'error'
          });
          return;
        }
        if(!row.proportionSelf){
          this.$message({
            message: '请输入返佣比例',
            type: 'error'
          });
          return;
        }
        if(!(noZeroInit(row.proportionSelf) && row.proportionSelf <= 100) && +row.proportionSelf !== 0){
          this.$message({
            message: '请输入0-100的整数',
            type: 'error'
          });
          return;
        }
        if(row.id){
          //编辑
          updateLevel({
            id: row.id,
            levelName: row.levelName,
            proportion: row.proportionSelf / 100,
            siteId: this.$route.query.siteId,
          }).then((res) => {
            this.pullAjaxRateData();
            this.$message({
              message: '操作成功'
            })
          })
        }else {
          //新增
          addLevel({
            id: row.id,
            levelName: row.levelName,
            proportion: row.proportionSelf / 100,
            siteId: this.$route.query.siteId,
          }).then((res) => {
            this.pullAjaxRateData();
            this.$message({
              message: '操作成功'
            })
          })
        }
      },
      deleteHandle(row,index) {
        deleteLevel({
          id: row.id
        }).then((res) => {
          this.pullAjaxRateData();
          this.$message({
            message: '操作成功'
          })
        })
      },
      cancelHandle(row) {
        this.commissionList = this.cacheRebateData;
      },
    }
  }
</script>

<style lang="less" scoped>
.rate-table {
  .span-btn {
    padding: 0 10px;
    cursor: pointer;
  }
}
</style>
