<template>
    <div class="themestyle">

           <el-form-item label="社区模版">
             <el-radio-group v-model="siteInfo.templateType" size='medium'>
              <el-radio-button
                v-for="(item, index) in themes"
                border
                :key="index"
                :label="item.value"
              >{{item.name}}</el-radio-button>
            </el-radio-group>
          </el-form-item>
           <el-form-item label="社区主题" prop="themeType">
            <el-radio-group v-model="siteInfo.themeType" size='medium'>
              <el-radio-button
                v-for="(item, index) in themeColors"
                border
                :disabled="computedDisabled(index)"
                :key="index"
                :label="item.themeType"
              >{{item.themeTypeName}}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col :span="3">
              <el-form-item label="主题色" prop="themeColor">
                <el-input class="mar-bot10" v-model="siteInfo.themeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.themeColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="第二主题色" prop="secondThemeColor">
                <el-input class="mar-bot10" v-model="siteInfo.secondThemeColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.secondThemeColor"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="导航背景" prop="headerBgc">
                <el-input class="mar-bot10"  v-model="siteInfo.headerBgc" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.headerBgc"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="底部背景" prop="footerBgc">
                <el-input class="mar-bot10"  v-model="siteInfo.footerBgc" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.footerBgc"></el-color-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="侧边栏背景色" prop="rightBarBackgroundColor">
                <el-input class="mar-bot10" v-model="siteInfo.rightBarBackgroundColor" style="width: 100px;" :disabled="siteInfo.themeType!=6"/>
                <el-color-picker :disabled="siteInfo.themeType!=6" v-model="siteInfo.rightBarBackgroundColor"></el-color-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item  v-if="siteInfo.themeType!=6" label="主题示例图片" prop="">
            <img :src="exampleImg"
                 class="example-img"
                 :class="{'four-or-five': siteInfo.templateType == 4 || siteInfo.templateType == 5}"
            >
          </el-form-item>
          <el-form-item label="首页banner图" prop="">
            <img
              v-if="siteInfo.bannerBg"
              class="banner-img"
              :class="{'four-or-five': siteInfo.templateType == 4 || siteInfo.templateType == 5}"
              :src="siteInfo.bannerBg"
            />
            <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :on-success="handleSuccess4"
                :file-list="fileLists"
                list-type="picture"
                :show-file-list='false'>
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
          </el-form-item>

          <el-row>
            <el-col :span="4">
              <el-form-item label="导航栏logo" prop="navibarLogo">
                <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :on-success="handleSuccess"
                  :file-list="fileLists"
                  list-type="picture"
                  :show-file-list='false'>
                  <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                </el-upload>
                <img v-if="siteInfo.navibarLogo" :src="siteInfo.navibarLogo" :style="{background: siteInfo.headerBgc}" class="logo-class">
              </el-form-item>
              </el-col>
              <el-col :span="4" >
                <el-form-item label="底部导航栏logo" prop="footBarLogo">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess1"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.footBarLogo"  :style="{background: siteInfo.footerBgc}" :src="siteInfo.footBarLogo"  class="foot-logo-class">
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item label="ICON" prop="icon">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess5"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.icon" :src="siteInfo.icon" class="icon-logo-class">
                </el-form-item>
              </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客服微信二维码" prop="weixinQcodeCustService">
                <el-upload
                  class="upload-demo"
                  :action="uploadUrl"
                  :on-success="handleSuccess2"
                  :file-list="fileLists"
                  :show-file-list='false'
                  list-type="picture">
                  <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                </el-upload>
                <img v-if="siteInfo.weixinQcodeCustService" :src="siteInfo.weixinQcodeCustService" class="weixinQcodeCust-logo-class">
              </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公众号二维码" prop="weixinQcodePub">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleSuccess3"
                    :file-list="fileLists"
                    :show-file-list='false'
                    list-type="picture">
                    <el-button class="mar-bot10" size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <img v-if="siteInfo.weixinQcodePub" :src="siteInfo.weixinQcodePub" class="weixinQcodePub-logo-class">
                </el-form-item>
              </el-col>
              <div style="width: 1000px;text-align: right;margin: 50px 0;">
                <el-button
                  plain
                  @click="cancel"
                >取消</el-button>
                <el-button
                  class="mar-top10"
                  :loading="loading"
                  type="primary"
                  @click="doSubmit()"
                >确认</el-button>
              </div>
          </el-row>
    </div>
</template>
<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
    props: {
       siteInfo: {
         type:Object,
         required:true,
         default(){
           return {
             themeType: 1,
             templateType: '1'
           }
         },
       }
    },
    computed:{
      themeColors(){
        if(this.siteInfo.templateType == 1){
          return this.themeColor1;
        }else if(this.siteInfo.templateType == 2){
          return this.themeColor2;
        }else if(this.siteInfo.templateType == 3){
          return this.themeColorTemplateC;
        }else if(this.siteInfo.templateType == 4){
          return this.themeColorTemplateD;
        }
      },
      themes(){
        if(this.checkPermission(['ADMIN'])){
          return [
            {value:1,name:'社区2.0'},
            {value:2,name:'社区3.0'},
            {value:3,name:'社区4.0'},
            {value:4,name:'社区5.0'},
          ];
        }else {
          switch (this.siteInfo.templateType) {
            case 1:
              return [
                {value:"1",name:'社区2.0'}
              ];
            case 2:
              return [
                {value:"2",name:'社区3.0'}
              ];
            case 3:
              return [
                {value:"3",name:'社区4.0'}
              ];
            case 4:
              return [
                {value:"4",name:'社区5.0'}
              ];
            default:
              return [
                {value:"1",name:'社区2.0'}
              ];
          }
        }
      },
    },
    data() {
      return {
        uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
        loading:false,
        fileLists:[],
        exampleImg:'',
        templateType:"1",
        themeColor2:[
          {
            themeType: 1,
            themeTypeName: "红色",
            themeColor: "#ff0000",
            secondthemeColor: "#fe3f3f",
            headerBgc: "#ffffff",
            footerBgc: "#260423",
            rightBarBackgroundColor: "#ff0000",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/9b6519581c984a61a1dace501a3c71e1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/32c728284cc9421a87f81e5a915fd320.png"
            },
            {
            themeType: 2,
            themeTypeName: "橙色",
            themeColor: "#fd5e17",
            secondthemeColor: "#fe793e",
            headerBgc: "#ffffff",
            footerBgc: "#185ae6",
            rightBarBackgroundColor: "#fd5e17",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/06dd78bc956c48d2b19304d2fbb5b377.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/765f38875aca4060bf4a8722c27fd75b.png"

            },
            {
            themeType: 3,
            themeTypeName: "黄色",
            themeColor: "#fdaf2e",
            secondthemeColor: "#fec465",
            headerBgc: "#ffffff",
            footerBgc: "#191140",
            rightBarBackgroundColor: "#fdaf2e",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/43517ef6147e4e4e8736390d4d6cfaf6.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/8d16730825c848f985538d262f6c539d.png"

            },
            {
            themeType: 4,
            themeTypeName: "绿色",
            themeColor: "#5bdab0",
            secondthemeColor: "#77e8c3",
            headerBgc: "#ffffff",
            footerBgc: "#45a7ef",
            rightBarBackgroundColor: "#5bdab0",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e799cad83eda449dba684fe52c509983.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/93d178a806c347528debc380d99fe3d8.png"

            },
            {
            themeType: 5,
            themeTypeName: "蓝色",
            themeColor: "#01c3ff",
            secondthemeColor: "#52d6ff",
            headerBgc: "#ffffff",
            footerBgc: "#4120c2",
            rightBarBackgroundColor: "#01c3ff",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/cedac296c9794c7da4a3b6d7df5a5475.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/ee48a6ae08f44fa9a36c564b25419645.png"
            },
            {
            themeType: 6,
            themeTypeName: "自定义",
            }
        ],
        themeColor1:[
            {
            themeType: 1,
            themeTypeName: "红色",
            themeColor: "#E74C3C",
            secondthemeColor: "#FA6758",
            headerBgc: "#333333",
            footerBgc: "#454545",
            rightBarBackgroundColor: "#E74C3C",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/2dd169f025984fc983b56ae9f9adbaaa.jpg",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/ec0d93685b264f6fbe4ed16864dbc9f0.png"
            },
            {
            themeType: 2,
            themeTypeName: "橙色",
            themeColor: "#FA6C0F",
            secondthemeColor: "#FF8536",
            headerBgc: "#333333",
            footerBgc: "#454545",
            rightBarBackgroundColor: "#FA6C0F",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/cda132da9fe945bebc04ac683a5a5a44.jpg",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/85875d39061d46c4864e054a96ad104f.png"

            },
            {
            themeType: 3,
            themeTypeName: "黄色",
            themeColor: "#FFBA02",
            secondthemeColor: "#FFD053",
            headerBgc: "#333333",
            footerBgc: "#454545",
            rightBarBackgroundColor: "#FFBA02",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/ac82717994c04508935e1c89ea2efb3d.jpg",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1e42dfbfefbc4108bdf16cd8ba815309.png"

            },
            {
            themeType: 4,
            themeTypeName: "绿色",
            themeColor: "#11AC80",
            secondthemeColor: "#46D3AB",
            headerBgc: "#353938",
            footerBgc: "#414343",
            rightBarBackgroundColor: "#11AC80",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/eafa33139a4e4fb9b00242333fc4a14c.jpg",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/17aa125f2c82428c8b6ba397a0f3a323.png"

            },
            {
            themeType: 5,
            themeTypeName: "蓝色",
            themeColor: "#207AF4",
            secondthemeColor: "#59A0FF",
            headerBgc: "#2A2E32",
            footerBgc: "#33373B",
            rightBarBackgroundColor: "#207AF4",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1a08ec2400c74ad18fa7ef96592de38a.jpg",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/1d43083d550b45df9c16a8bd4f940745.png"
            },
            {
            themeType: 6,
            themeTypeName: "自定义",
            }
        ],
        themeColorTemplateC:[
          {
            themeType: 1,
            themeTypeName: "红色",
            themeColor: "#ff0000",
            secondthemeColor: "#fe3f3f",
            headerBgc: "#ffffff",
            footerBgc: "#260423",
            rightBarBackgroundColor: "#ff0000",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/3020f31ed3c94466957947fdccfa97d1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c5b1990c821d45d79ae21a6de795707d.png"
          },
          {
            themeType: 2,
            themeTypeName: "橙色",
            themeColor: "#fd5e17",
            secondthemeColor: "#fe793e",
            headerBgc: "#ffffff",
            footerBgc: "#185ae6",
            rightBarBackgroundColor: "#fd5e17",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/3020f31ed3c94466957947fdccfa97d1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c5b1990c821d45d79ae21a6de795707d.png"
          },
          {
            themeType: 3,
            themeTypeName: "黄色",
            themeColor: "#fdaf2e",
            secondthemeColor: "#fec465",
            headerBgc: "#ffffff",
            footerBgc: "#191140",
            rightBarBackgroundColor: "#fdaf2e",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/3020f31ed3c94466957947fdccfa97d1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c5b1990c821d45d79ae21a6de795707d.png"
          },
          {
            themeType: 4,
            themeTypeName: "绿色",
            themeColor: "#5bdab0",
            secondthemeColor: "#77e8c3",
            headerBgc: "#ffffff",
            footerBgc: "#45a7ef",
            rightBarBackgroundColor: "#5bdab0",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/3020f31ed3c94466957947fdccfa97d1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c5b1990c821d45d79ae21a6de795707d.png"

          },
          {
            themeType: 5,
            themeTypeName: "蓝色",
            themeColor: "#4659E2",
            secondthemeColor: "#6878E4",
            headerBgc: "#ffffff",
            footerBgc: "#4120c2",
            rightBarBackgroundColor: "#4659E2",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/3020f31ed3c94466957947fdccfa97d1.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/c5b1990c821d45d79ae21a6de795707d.png"
          },
          {
            themeType: 6,
            themeTypeName: "自定义",
          }
        ],
        themeColorTemplateD:[
          {
            themeType: 1,
            themeTypeName: "红色",
            themeColor: "#ff0000",
            secondthemeColor: "#fe3f3f",
            headerBgc: "#ffffff",
            footerBgc: "#260423",
            rightBarBackgroundColor: "#ff0000",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e3605399752d42d49fe0a9392d1d3f4c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e8efce51c6a14ac8bd9912c2037fa595.png"
          },
          {
            themeType: 2,
            themeTypeName: "橙色",
            themeColor: "#fd5e17",
            secondthemeColor: "#fe793e",
            headerBgc: "#ffffff",
            footerBgc: "#185ae6",
            rightBarBackgroundColor: "#fd5e17",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e3605399752d42d49fe0a9392d1d3f4c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e8efce51c6a14ac8bd9912c2037fa595.png"

          },
          {
            themeType: 3,
            themeTypeName: "黄色",
            themeColor: "#fdaf2e",
            secondthemeColor: "#fec465",
            headerBgc: "#ffffff",
            footerBgc: "#191140",
            rightBarBackgroundColor: "#fdaf2e",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e3605399752d42d49fe0a9392d1d3f4c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e8efce51c6a14ac8bd9912c2037fa595.png"

          },
          {
            themeType: 4,
            themeTypeName: "绿色",
            themeColor: "#5bdab0",
            secondthemeColor: "#77e8c3",
            headerBgc: "#ffffff",
            footerBgc: "#45a7ef",
            rightBarBackgroundColor: "#5bdab0",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e3605399752d42d49fe0a9392d1d3f4c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e8efce51c6a14ac8bd9912c2037fa595.png"

          },
          {
            themeType: 5,
            themeTypeName: "蓝色",
            themeColor: "#00CAE1",
            secondthemeColor: "#52d6ff",
            headerBgc: "#ffffff",
            footerBgc: "#4120c2",
            rightBarBackgroundColor: "#00CAE1",
            bannerBg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e3605399752d42d49fe0a9392d1d3f4c.png",
            exampleImg:"https://oss-hk2069.oss-accelerate.aliyuncs.com/image/e8efce51c6a14ac8bd9912c2037fa595.png"
          },
          {
            themeType: 6,
            themeTypeName: "自定义",
          }
        ],
      }
  },
    watch:{
    'siteInfo.themeType': {
        handler(num){
            if(num<6) {
              var nums= num-1;
              this.siteInfo.themeColor=this.themeColors[nums].themeColor
              this.siteInfo.secondThemeColor=this.themeColors[nums].secondthemeColor
              this.siteInfo.headerBgc=this.themeColors[nums].headerBgc
              this.siteInfo.footerBgc=this.themeColors[nums].footerBgc
              this.siteInfo.rightBarBackgroundColor=this.themeColors[nums].rightBarBackgroundColor
              this.siteInfo.bannerBg=this.themeColors[nums].bannerBg
              this.exampleImg=this.themeColors[nums].exampleImg
            }
        },
        deep: true
    },
    'siteInfo.templateType': {
        handler(num){
          this.siteInfo.themeType =1
          var nums =0;
          this.siteInfo.themeColor=this.themeColors[nums].themeColor;
          this.siteInfo.secondThemeColor=this.themeColors[nums].secondthemeColor;
          this.siteInfo.headerBgc=this.themeColors[nums].headerBgc;
          this.siteInfo.footerBgc=this.themeColors[nums].footerBgc;
          this.siteInfo.rightBarBackgroundColor=this.themeColors[nums].rightBarBackgroundColor;
          this.siteInfo.bannerBg=this.themeColors[nums].bannerBg;
          this.exampleImg=this.themeColors[nums].exampleImg;
          if(this.siteInfo.templateType == 3 || this.siteInfo.templateType == 4){
            this.siteInfo.themeType = 5;
            let nomalIndex = 5;
            this.siteInfo.themeColor=this.themeColors[nomalIndex].themeColor;
            this.siteInfo.secondThemeColor=this.themeColors[nomalIndex].secondthemeColor;
            this.siteInfo.headerBgc=this.themeColors[nomalIndex].headerBgc;
            this.siteInfo.footerBgc=this.themeColors[nomalIndex].footerBgc;
            this.siteInfo.rightBarBackgroundColor=this.themeColors[nomalIndex].rightBarBackgroundColor;
            this.siteInfo.bannerBg=this.themeColors[nomalIndex].bannerBg;
            this.exampleImg=this.themeColors[nomalIndex].exampleImg;
          }
        },
        deep: true
    }
   },
    created() {
        if(this.siteInfo.themeType==null ){
            this.siteInfo.themeType = 1
        }else if(this.siteInfo.themeType==6 ){
          this.themeColors[5].themeColor = this.siteInfo.themeColor
          this.themeColors[5].secondthemeColor = this.siteInfo.secondThemeColor
          this.themeColors[5].headerBgc = this.siteInfo.headerBgc
          this.themeColors[5].footerBgc = this.siteInfo.footerBgc
          this.themeColors[5].rightBarBackgroundColor = this.siteInfo.rightBarBackgroundColor
          this.themeColors[5].bannerBg = this.siteInfo.bannerBg
        }else {
          this.exampleImg=this.themeColors[this.siteInfo.themeType-1].exampleImg
        }
   },
  methods: {
    computedDisabled(index){
      if(this.siteInfo.templateType == 3 || this.siteInfo.templateType == 4){
        if(index == 4){
          return false;
        }else {
          return true;
        }
      }else {
        return false;
      }
    },
    checkPermission,
    doSubmit() {
      this.$emit('doSubmit')
    },
    cancel() {

    },
    handleSuccess(response) {
      this.$emit('handleSuccess', response)
      //上传成功要处理的事
    },
    handleSuccess1(response) {
      //上传成功要处理的事
      this.$emit('handleSuccess1', response)
    },
    handleSuccess2(response) {
      this.$emit('handleSuccess2', response)
      //上传成功要处理的事
    },
    handleSuccess3(response) {
      this.$emit('handleSuccess3', response)
      //上传成功要处理的事
    },
    handleSuccess5(response) {
      this.$emit('handleSuccess5', response)
      //上传成功要处理的事
    },
    handleSuccess4(response) {
      this.$emit('handleSuccess4', response)
      //上传成功要处理的事
    }
  }
}
</script>
<style lang="less" scoped>

  .logo-class {
    width: auto;
    height: 30px;
  }

  .foot-logo-class {
    width: auto;
    height: 38px;
  }

  .icon-logo-class {
    width: 40px;
    height: 40px;
  }

  .weixinQcodeCust-logo-class {
    width: 75px;
    height: 75px;
  }

  .weixinQcodePub-logo-class {
    width: 125px;
    height: 125px;
  }
  .example-img,
  .banner-img {
    width: 1000px;height:343px;
    &.four-or-five {
      height: 538px;
    }
  }
</style>
