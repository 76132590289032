<template>
  <el-form
    class="feature-form"
    ref="form"
    :model="form"
    :rules="rules"
  >
    <el-form-item class="trade" prop="masterMaxNumber" label="交易账号数">
      <el-radio @change="tradeChangeHandle" v-model="form.isLimitTradeNumber" :label="1">不限</el-radio>
      <el-radio @change="tradeChangeHandle" v-model="form.isLimitTradeNumber" :label="2">限制</el-radio>
      <el-input v-if="form.isLimitTradeNumber == 2" class="number-input" v-model="form.tradeNumber"></el-input>
    </el-form-item>
    <el-form-item class="copy" prop="copyMaxNumber" label="跟随账号数">
      <el-radio @change="copyChangeHandle" v-model="form.isLimitFollowNumber" :label="1">不限</el-radio>
      <el-radio @change="copyChangeHandle" v-model="form.isLimitFollowNumber" :label="2">限制</el-radio>
      <el-input v-if="form.isLimitFollowNumber == 2" class="number-input" v-model="form.followNumber"></el-input>
    </el-form-item>
    <el-form-item prop="isUseSharedSignals" label="使用共享信号">
      <el-radio v-model="form.isUseSharedSignals" :label="1">使用</el-radio>
      <el-radio v-model="form.isUseSharedSignals" :label="0">不使用</el-radio>
    </el-form-item>
    <el-form-item label="跟单方向">
      <el-checkbox v-model="copyDirectionFront" disabled>正向</el-checkbox>
      <el-checkbox v-model="form.copyDirectionReverse">反向</el-checkbox>
    </el-form-item>
    <el-form-item prop="copyType" label="跟单方式">
      <el-checkbox-group v-model="form.copyType">
        <el-checkbox label="3" name="type">智能比例</el-checkbox>
        <el-checkbox label="2" name="type">固定比例</el-checkbox>
        <el-checkbox label="1" name="type">固定数量</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item
        prop="rankingSql"
        label="社区上榜条件"
    >
      <!--      <el-input
          type="textarea"
          class=""
          style="width: 280px"
          placeholder="请输入SQL语句，SQL格式为“AND t2.profit_rate > 0  ”，每个子条件都以AND开头"
          v-model="form.rankingSql"
          :row="6"
      ></el-input>-->
      <el-button
          size="mini"
          @click="addHandle"
      >+添加</el-button>
      <div
          class="rankingSql-item"
          v-for="(item,index) in rankingSqlList"
          :key="index"
      >
        <div class="sql-item-left">
          <el-select
              style="width: 140px"
              placeholder="指标"
              v-model="item.column"
          >
            <el-option
              v-for="(itemOption,i) in indexValueOptionList"
              :label="itemOption.indexName"
              :value="itemOption.indexCode"
            ></el-option>
          </el-select>
          <el-select
              style="width: 100px"
              placeholder="请选择"
              v-model="item.symbol"
          >
            <el-option
                v-for="(itemOption,i) in symbolValueOptionList"
                :label="itemOption.label"
                :value="itemOption.value"
            ></el-option>
          </el-select>
          <el-input
              style="width: 100px"
            placeholder="数值"
            v-model="item.number"
          ></el-input>
        </div>
        <div
            @click="removeHandle(index)"
            class="sql-item-right pointer">
          移除
        </div>
      </div>
    </el-form-item>
    <el-form-item class="last-form-item">
      <el-button size="mini" @click="calcleHanlde">取消</el-button>
      <el-button type="primary" size="mini" @click="submitHandle">确认</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { noZeroInit } from '@/utils/validate'
  import { editSiteFeature, getSiteConfig,querySrSitePayType } from "@/api/cms/srSiteConfig";
  import {initData} from "@/api/data";
  export default {
    name: 'Feature',
    data() {
      let _this = this;
      let validateMaxAccount = (rule,value,callback) => {
        if (!_this.form.tradeNumber && this.form.isLimitTradeNumber == 2) {
          callback(new Error('交易员账户数限制不能为空'));

        }
        setTimeout(() => {
          if (!noZeroInit(_this.form.tradeNumber) && this.form.isLimitTradeNumber == 2) {
            console.log(noZeroInit(_this.form.tradeNumber));
            callback(new Error('请输入正整数'));
            return;
          } else {
            callback();
          }
        }, 500);
      };
      let validateMaxCopyAccount = (rule,value,callback) => {
        if (!_this.form.followNumber && this.form.isLimitFollowNumber == 2) {
          return callback(new Error('跟随者账户数限制不能为空'));
        }
        setTimeout(() => {
          if (!noZeroInit(_this.form.followNumber) && this.form.isLimitFollowNumber == 2) {
            callback(new Error('请输入正整数'));
          } else {
            callback();
          }
        }, 500);
      };

      return {
        copyDirectionFront: true,
        form: {
          isLimitTradeNumber:  1,
          tradeNumber:  '',
          isLimitFollowNumber:  1,
          followNumber:  '',
          isUseSharedSignals:  1,
          copyDirectionReverse: false,
          copyType: ['1','2','3'],
          rankingSql: '',
        },
        rules: {
          masterMaxNumber: [
            { required: true, validator: validateMaxAccount,trigger: "blur" },
          ],
          copyMaxNumber: [
            { required: true, validator: validateMaxCopyAccount,trigger: "blur" },
          ],
          isUseSharedSignals: [
            { required: true, message: '请选择是否使用共享信号', trigger: 'change' }
          ],
          copyType: [
            { type: 'array', required: true, message: '请至少选择一个跟单方式', trigger: 'change' }
          ],
          // rankingSql, 非必填
        },
        configId: 0,

        rankingSqlList: [
          // { column: '', symbol: '', valueData: '' },
        ],

        indexValueOptionList: [],
        symbolValueOptionList: [
          {label: '<',value: '<'},
          {label: '>',value: '>'},
          {label: '≤',value: '<='},
          {label: '≥',value: '>='},
        ],
        sqlItemOne: { column: '', symbol: '', number: '' },
      }
    },
    created(){
      this.getFeatureData();
      this.getPayTypeConfig();
      this.initIndexList();
    },
    methods: {
      initIndexList(){
        const url = 'community/crm/srSignalIndexList'
        const params = {}
        initData(url, params).then(res => {
          // let data = []
          // res.content.forEach((item, index) => {
          //   data.push({
          //     label: item.indexName,
          //     key: item.indexCode,
          //   });
          // });
          this.indexValueOptionList=  res.content
        }).catch(err => {
          reject(err)
        })
      },
      // 订阅设置获取数据
      addHandle(){
        let one = { column: '', symbol: '', number: '' }
        this.rankingSqlList.push(one);
      },
      removeHandle(index){
        this.rankingSqlList.splice(index,1);
      },
      getFeatureData() {
        var params = {
          siteId:this.$route.query.siteId
        }
        getSiteConfig(params).then(res => {
          this.configId = res.id;
          if(res.masterMaxNumber == -1){
            this.form.isLimitTradeNumber = 1;
          }else {
            this.form.isLimitTradeNumber = 2;
            this.form.tradeNumber = res.masterMaxNumber;
          }
          if(res.copyMaxNumber == -1){
            this.form.isLimitFollowNumber = 1;
          }else {
            this.form.isLimitFollowNumber = 2;
            this.form.followNumber = res.copyMaxNumber;
          }

          this.form.isUseSharedSignals = res.site.sharable;
          this.form.copyDirectionReverse = res.reverseCopyFlag == 1 ? true: false;
          this.form.copyType = res.copyTypes;

          // 新增加的
          this.form.commission = res.site.openCommission;
          this.form.commissionLevels = res.site.hierarchy;
          this.form.fees = res.site.openSettlement;
          // 社区上榜条件
          this.rankingSqlList = res.rankingSqlList || [];
        });
      },
      getPayTypeConfig(){
        querySrSitePayType({
          siteId: this.$route.query.siteId,
        }).then((res) => {
          for (let i = 0; i <res.length; i++) {
            if(res[i].payTypeId == 1){
              this.form.alipay = 1;
            }
            if(res[i].payTypeId == 2){
              this.form.weChat = 1;
            }
            if(res[i].payTypeId == 3){
              this.form.wallet = 1;
            }
          }
        })
      },
      calcleHanlde() {
        this.$router.go(-1);
      },
      submitHandle(){
        // validate
        this.$refs.form.validate((valid) => {
          if(valid){
            let flag = true;
            for (let i = 0; i < this.rankingSqlList.length; i++) {
              if(!this.rankingSqlList[i].column){
                this.$message({
                  type: 'warning',
                  message: '请选择数据指标',
                });
                flag = false;
                break;
              }
              if(!this.rankingSqlList[i].symbol){
                this.$message({
                  type: 'warning',
                  message: '请选择数据指标范围',
                });
                flag = false;
                break;
              }
              if(!this.rankingSqlList[i].number){
                this.$message({
                  type: 'warning',
                  message: '请输入数据指标百分比数值',
                });
                flag = false;
                break;
              }
            }
            if(!flag){
              return;
            }
            let params = {
              id: this.configId,
              masterMaxNumber: this.form.isLimitTradeNumber == 1 ? -1: this.form.tradeNumber,
              copyMaxNumber: this.form.isLimitFollowNumber == 1 ? -1 : this.form.followNumber,
              sharable: this.form.isUseSharedSignals,
              reverseCopyFlag: this.form.copyDirectionReverse ? 1: 0,
              availableCopyType: this.form.copyType.join(','),
              rankingSqlList: this.rankingSqlList,

              wallet: this.form.wallet,
              openCommission: this.form.commission,
              hierarchy: this.form.commissionLevels,
              openSettlement: this.form.fees,
              aliPay: this.form.alipay,
              weChat: this.form.weChat,

            }
            editSiteFeature(params).then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                message: '修改成功'
              });
            })
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      tradeChangeHandle(){
        this.$refs.form.clearValidate('masterMaxNumber');
      },
      copyChangeHandle(){
        this.$refs.form.clearValidate(['copyMaxNumber']);
      },

    }
  }
</script>
<style lang="less">
  .feature-form {
    .trade,
    .copy {
      &.is-error {
        .el-form-item__error {
          padding-left: 260px;
        }
      }
    }
  }
</style>
<style lang="less" scoped>
.feature-form {
  .last-form-item {
    padding-left: 100px;
  }
  .number-input {
    width: 100px;
  }
  .rankingSql-item {
    padding-left: 95px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 12px;
    .sql-item-right {
      padding: 20px;
      padding-top: 0;
      padding-bottom: 0;
      color: #409EFF;
    }
  }
}
</style>
